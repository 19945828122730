import { FC, useMemo } from 'react';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const NumberInputPreview: FC<ActionPreviewBaseProps<string>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  if (answerOnly) {
    return <>{response || '-'}</>;
  }

  return <ActionPreview data-cy="text-input-preview" question={title} description={previewDescription} answer={response || '-'} />;
};

export default NumberInputPreview;
